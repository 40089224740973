// Customizable Area Start
import React, {Suspense}  from "react";
import CatalogueController, { Props } from "./CatalogueController.web";
import { Box, Breadcrumbs, withStyles, Theme, createStyles, CircularProgress } from "@material-ui/core";
import { NavigateNext as NavigateNextIcon } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
const ImageCard = React.lazy(() => import('../../utilities/src/components/ImageCard.web'));
import { ServiceImage } from "../../utilities/src/models/ServiceImage";
import Currency from "../../utilities/src/components/Currency.web";
import Skeleton from "@material-ui/lab/Skeleton";
export const configJSON = require("./config");
// Customizable Area End

class ServiceWrapper extends CatalogueController {
  render() {
    // Customizable Area Start
    const {
      service,
      selectedImageIndex,
      renameService
    } = this.state;

    const style = this.props.classes;
    return (
      <>

          <>
            <Box className={style.breadcrumbsWrapper}>
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" style={{color: "black"}} />}>
                <Box onClick={this.homeNavigation} className={style.breadcrumbLink}>
                  <Typography>{configJSON.textHome}</Typography>
                </Box>
                <Box
                  className={style.breadcrumbLink}
                  onClick={this.servicesNavigation}
                >
                  <Typography>{renameService ? renameService : "Services"}</Typography>
                </Box>
                <Typography className={style.breadcrumbNonLink}>
                  {service?.title}
                </Typography>
              </Breadcrumbs>
            </Box>
            <Box className={style.currentCatalogueWrapper}>
              <Box className={style.imagesWrapper}>
              {service ?
                <>
                <Box className={style.largeImageWrapper}>
                  <Suspense fallback={<CircularProgress />}>
                    <ImageCard
                      imageClassName={style.largeImage}
                      image={
                        service.images &&
                        service.images[selectedImageIndex] &&
                        (service.images[
                          selectedImageIndex
                        ] as ServiceImage).url
                      }
                      alt="image loading"
                    />
                  </Suspense>
                </Box>
                </>
                :
                <Skeleton variant="rect" width={424} height={352} />
              }
              {
                service &&
                <Box className={style.smallImagesWrapper}>
                  {service?.images.length > 1 && (service.images as ServiceImage[]).map(
                    (image, index) => (
                     image ? 
                      <Suspense fallback={<CircularProgress />}>
                        <ImageCard
                          key={index}
                          imageClassName={style.smallImage}
                          image={image.small_url ? image.small_url: image.url}
                          onClick={() => this.previewImage(index)}
                          alt="image loading"
                        />
                      </Suspense> :
                      <div>
                        <Skeleton variant="rect" width={85} height={83} />
                      </div>
                    )
                  )}
                </Box>
              }
              </Box>

            {service ?
              <Box className={style.details}>
                <Box>
                  <Typography className={style.infoTitle}>
                    {`${service.category.name}`}
                  </Typography>
                  <Typography className={style.catalogueName}>{`${service.title
                    }`}</Typography>
                </Box>

                <Box>
                  <Typography className={style.infoTitle}>
                    {configJSON.textDuration}
                  </Typography>
                  <Typography className={style.info}>
                    {`${service.duration} ${configJSON.textMins}`}
                  </Typography>
                </Box>

                <Box>
                  <Typography className={style.infoTitle}>
                    {configJSON.textPrice}
                  </Typography>
                  <Typography className={style.info}>
                    <Currency
                      text={`${service.discountedPrice.toFixed(2)} `} disountPrice={`${service.undiscountedPrice.toFixed(2)}`} time={""} hasDiscount={service.hasDiscount}                    />
                  </Typography>
                </Box>

                <Box>
                  <Typography className={style.infoTitle}>
                    {configJSON.textDescription}
                  </Typography>
                  <Typography className={style.info}>
                    {service.description}
                  </Typography>
                </Box>

                <Typography
                  className={style.bookNowButton}
                  onClick={() => this.appoinmentNavigation(service.id)}
                >
                  {configJSON.textBookNow}
                </Typography>
              </Box>
            :
              <Box className={style.skeletonBox}>
                <Skeleton />
                <Skeleton width={"60%"} />
              </Box>
            }
            </Box>
          </>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Styles = (theme: Theme) =>
  createStyles({
    breadcrumbsWrapper: {
      margin: theme.spacing(5, 10, 1),
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(2, 2.25, 1),
        display: "flex"
      },
    },

    breadcrumbLink: {
      color: "#3FC1CB",
      textDecoration: "none",
      cursor: "pointer",
    },

    breadcrumbNonLink: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "130px"
    },
    currentCatalogueWrapper: {
      display: "flex",
      gap: theme.spacing(16),
      padding: theme.spacing(6.25, 10, 11.25),
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        gap: theme.spacing(1.75),
        padding: theme.spacing(2.5),
      },
    },
    imagesWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        gap: theme.spacing(1.25),
      },
    },
    largeImageWrapper: {
      display: "flex",
      width: "628px",
      height: "611px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "335px",
      },
    },
    largeImage: {
      flex: "1 1 auto",
    },
    smallImagesWrapper: {
      display: "flex",
      width: "628px",
      gap: theme.spacing(3),
      overflow: "auto",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        gap: theme.spacing(1.25),
      },
    },
    smallImage: {
      width: "85px",
      height: "83px",
      flex: "0 0 auto",
      cursor: "pointer",
    },
    details: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: theme.spacing(5),
      marginTop: theme.spacing(8),
      [theme.breakpoints.down("sm")]: {
        gap: theme.spacing(3),
        marginTop: theme.spacing(0),
      },
    },
    infoTitle: {
      fontSize: "18px",
      lineHeight: "24px",
      color: "#757575",
      paddingBottom: theme.spacing(1),
    },
    catalogueName: {
      fontSize: "32px",
      lineHeight: "36px",
      letterSpacing: "3.2px",
      color: "#364F6B",
      textTransform: "uppercase",
    },
    servicesList: {
      paddingInlineStart: theme.spacing(5),
    },
    servicesListItem: {
      display: "list-item",
      listStyleType: "disc",
      padding: theme.spacing(0),
    },
    info: {
      fontSize: "18px",
      lineHeight: "24px",
      color: "#000000",
    },
    link: {
      textDecoration: "none",
      display: "flex",
      width: "100%",
    },
    bookNowButton: {
      textAlign: "center",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
      backgroundColor: "#3FC1CB",
      color: "#FFFFFF",
      borderRadius: "5px",
      cursor: "pointer",
      padding: "12px",
      width: "194px",
      '@media(max-width: 480px)': {
        width: '100%'
      },
      [theme.breakpoints.down("sm")]: {
        flex: 1,
      },
    },
    skeletonBox:{
      display: "flex",
      flexDirection: "column",
      width:"50%"
    }
  });
export default withStyles(Styles, { withTheme: true })(ServiceWrapper);
// Customizable Area End